var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"consent-field flex flex-col gap-2",class:`consent-field--${_vm.type}`},[_c('div',{staticClass:"w-full flex flex-wrap justify-between md:flex-row items-baseline pt-2 .consentt"},[(_vm.previewMode || _vm.personalDetails || _vm.userData)?_c('div',{staticClass:"consent-field__personal-details mr-4 flex print--none"},[_vm._v(" "+_vm._s(_vm.$t('form_builder.do_you_agree_with_this_consent'))+" "),(_vm.fieldData.mandatory)?_c('span',{staticClass:"inline text-red-500"},[_vm._v(_vm._s(_vm.$t('form_builder.asterisk')))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"username-section"},[_c('span',{staticClass:"mt-1 font-medium capitalize username print--none"},[(_vm.previewMode)?[_vm._v(_vm._s(_vm.$t('form_builder.first_name_last_name')))]:(_vm.personalDetails && _vm.personalDetails?.first_name)?[_vm._v(_vm._s(_vm.personalDetails.first_name)+" "+_vm._s(_vm.personalDetails.last_name))]:(_vm.userData)?[_vm._v(_vm._s(_vm.userData?.name))]:_vm._e()],2)]),_c('div',{staticClass:"consent-field__buttons flex items-center pt-1"},_vm._l((_vm.typeConfig[_vm.type].options),function(option,idx){return _c('div',{key:option.option_value + idx,staticClass:"form-check flex mr-4 print--none"},[_c('div',[_c('label',{staticClass:"flex",class:{ responsiveLabel: _vm.previewForm }},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getValue),expression:"getValue"}],staticClass:"form-check-input appearance-none h-4 w-4 border border-solid rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer",class:{
                                'border-box-stroke': !_vm.error,
                                'border-red-600': _vm.error,
                            },attrs:{"name":_vm.getName,"disabled":_vm.disabled || _vm.formAlreadySubmitted,"id":option.option_value + 'consent' + idx,"type":"checkbox"},domProps:{"value":option.option_value,"checked":Array.isArray(_vm.getValue)?_vm._i(_vm.getValue,option.option_value)>-1:(_vm.getValue)},on:{"change":[function($event){var $$a=_vm.getValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option.option_value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.getValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.getValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.getValue=$$c}},_vm.changeValue]}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getValue),expression:"getValue"}],staticClass:"form-check-input appearance-none h-4 w-4 border border-solid rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer",class:{
                                'border-box-stroke': !_vm.error,
                                'border-red-600': _vm.error,
                            },attrs:{"name":_vm.getName,"disabled":_vm.disabled || _vm.formAlreadySubmitted,"id":option.option_value + 'consent' + idx,"type":"radio"},domProps:{"value":option.option_value,"checked":_vm._q(_vm.getValue,option.option_value)},on:{"change":[function($event){_vm.getValue=option.option_value},_vm.changeValue]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getValue),expression:"getValue"}],staticClass:"form-check-input appearance-none h-4 w-4 border border-solid rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer",class:{
                                'border-box-stroke': !_vm.error,
                                'border-red-600': _vm.error,
                            },attrs:{"name":_vm.getName,"disabled":_vm.disabled || _vm.formAlreadySubmitted,"id":option.option_value + 'consent' + idx,"type":_vm.type},domProps:{"value":option.option_value,"value":(_vm.getValue)},on:{"change":_vm.changeValue,"input":function($event){if($event.target.composing)return;_vm.getValue=$event.target.value}}}),_c('span',{staticClass:"form-check-label inline-block text-gray-800 cursor-pointer capitalize no-print",attrs:{"for":option.option_value + 'consent' + idx}},[_vm._v(" "+_vm._s(option.option_label)+" ")])])])])}),0)])]),(_vm.signDate)?_c('div',{staticClass:"flex self-end no-print"},[_vm._v(" "+_vm._s(_vm.signDate)+" ")]):_vm._e(),_c('modalContent',{ref:"modal",staticClass:"modalContent",attrs:{"name":_vm.fieldData.field_fe_id,"title":_vm.$t('form_builder.consent'),"close":"","width":"90%","height":"88%","showFooter":false,"isConsentModal":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{attrs:{"clss":"modal-content w-full"}},[_c('div',{staticClass:"w-full consent-content",domProps:{"innerHTML":_vm._s(_vm.fieldData.placeholder)}})])]},proxy:true}])}),(!_vm.hasSignature)?_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-10/12 show-print"},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t('form_builder.applicant_name')))])]),_vm._v(" "+_vm._s(_vm.personalDetails?.first_name)+" "+_vm._s(_vm.personalDetails?.last_name)+" ")]),_c('div',{staticClass:"w-2/12 show-print"},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t('form_builder.date')))])]),_vm._v(" "+_vm._s(_vm.convertedSignDate)+" ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }